<template>
  <ui-component-modal
    :modalTitle="'Copy price & yield settings'"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="copyYieldSettings"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <table class="table is-fullwidth is-striped is-hoverable">
        <tbody>
          <tr v-if="mYieldSettings.VoucherId > 0">
            <td>Voucher</td>
            <td>{{ voucher.Name }}</td>
          </tr>
          <tr v-if="mYieldSettings.VoucherId === 0">
            <td>Channel</td>
            <td>
              <div v-if="mYieldSettings.ChannelId > 0">{{ channelName }}</div>
              <div v-if="mYieldSettings.ChannelId === 0" class="select">
                <select v-model="mYieldSettings.ChannelId">
                  <option
                    :value="0"
                    :selected="mYieldSettings.ChannelId === 0"
                    >{{
                      $t('ModalCopyYieldSettings.Label_SelectChannel')
                    }}</option
                  >
                  <option
                    v-for="(channel, index) in locationState.Channels"
                    :key="index"
                    :value="channel.ChannelId"
                    :selected="channel.ChannelId === mYieldSettings.ChannelId"
                    >{{ channel.ChannelName }}</option
                  >
                </select>
              </div>
            </td>
          </tr>
          <tr>
            <td>Meeting type</td>
            <td>
              <div v-if="mYieldSettings.MeetingtypeId > 0">
                {{ mYieldSettings.MeetingtypeId | getMeetingtypeName }}
              </div>
              <div v-if="mYieldSettings.MeetingtypeId === 0" class="select">
                <select v-model="mYieldSettings.MeetingtypeId">
                  <option :value="0">{{
                    $t('ModalCopyYieldSettings.Label_SelectMeetingtype')
                  }}</option>
                  <option
                    v-for="(meetingtype, index) in meetingtypes"
                    :key="index"
                    :value="meetingtype"
                    :selected="mYieldSettings.MeetingtypeId === meetingtype"
                    >{{ meetingtype | getMeetingtypeName }}</option
                  >
                </select>
              </div>
            </td>
          </tr>
          <tr v-if="mYieldSettings.VoucherId === 0">
            <td>Space</td>
            <td>
              <div v-if="mYieldSettings.SpaceId > 0">
                {{ space.Name }}
              </div>
              <div
                v-if="mYieldSettings.SpaceId === 0"
                class="select"
                :class="{ 'is-loading': isLoadingSpaces }"
              >
                <select v-model="mYieldSettings.SpaceId">
                  <option :value="0">All spaces</option>
                  <option
                    v-for="(space, index) in spaces"
                    :key="index"
                    :value="space.Id"
                    :selected="mYieldSettings.SpaceId === space.Id"
                    >{{ space.Name }}</option
                  >
                </select>
              </div>
            </td>
          </tr>
          <tr>
            <td>Valid from</td>
            <td>
              <div v-if="mYieldSettings.VoucherId > 0">
                {{ voucher.ValidFrom | dateObjectIsoDateString }}
              </div>

              <v-date-picker
                mode="single"
                v-model="dateOne"
                :value="formatDates(dateOne)"
              >
                <input
                  type="text"
                  class="input is-small"
                  :id="'datepicker-trigger'"
                  readonly
                  :value="formatDates(dateOne)"
                />
              </v-date-picker>
            </td>
          </tr>
          <tr>
            <td>VAT</td>
            <td>
              <div class="select">
                <select v-model="mYieldSettings.TaxPercentage">
                  <option
                    v-for="(taxPercentage,
                    index) in locationState.TaxPercentages"
                    :key="index"
                    :value="taxPercentage.TaxPercentage"
                    :selected="
                      mYieldSettings.TaxPercentage ===
                        taxPercentage.TaxPercentage
                    "
                    >{{ taxPercentage.TaxPercentage }}%</option
                  >
                </select>
              </div>
            </td>
          </tr>
          <tr>
            <td>Calculation type</td>
            <td>
              <div class="select">
                <select v-model="mYieldSettings.CalculationType">
                  <option
                    v-for="(pct, index) in priceCalculationTypes"
                    :key="index"
                    :value="pct.type"
                    :selected="mYieldSettings.CalculationType === pct.type"
                    >{{ pct.name }}</option
                  >
                </select>
              </div>
            </td>
          </tr>
          <tr>
            <td>Activa location utilization</td>
            <td>
              <div class="tabs is-toggle is-small">
                <ul>
                  <li
                    :class="{
                      'is-active': mYieldSettings.ApplyLocationUtilization,
                    }"
                  >
                    <a @click="mYieldSettings.ApplyLocationUtilization = true">
                      <span>Yes</span>
                    </a>
                  </li>
                  <li
                    :class="{
                      'is-active': !mYieldSettings.ApplyLocationUtilization,
                    }"
                  >
                    <a @click="mYieldSettings.ApplyLocationUtilization = false">
                      <span>No</span>
                    </a>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
          <tr>
            <td>Activate space utilization</td>
            <td>
              <div class="tabs is-toggle is-small">
                <ul>
                  <li
                    :class="{
                      'is-active': mYieldSettings.ApplySpaceUtilization,
                    }"
                  >
                    <a @click="mYieldSettings.ApplySpaceUtilization = true">
                      <span>Yes</span>
                    </a>
                  </li>
                  <li
                    :class="{
                      'is-active': !mYieldSettings.ApplySpaceUtilization,
                    }"
                  >
                    <a @click="mYieldSettings.ApplySpaceUtilization = false">
                      <span>No</span>
                    </a>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
          <tr>
            <td>Activate day percentage</td>
            <td>
              <div class="tabs is-toggle is-small">
                <ul>
                  <li
                    :class="{ 'is-active': mYieldSettings.ApplyDayPercentage }"
                  >
                    <a @click="mYieldSettings.ApplyDayPercentage = true">
                      <span>Yes</span>
                    </a>
                  </li>
                  <li
                    :class="{ 'is-active': !mYieldSettings.ApplyDayPercentage }"
                  >
                    <a @click="mYieldSettings.ApplyDayPercentage = false">
                      <span>No</span>
                    </a>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
          <tr>
            <td>Activate group size</td>
            <td>
              <div class="tabs is-toggle is-small">
                <ul>
                  <li :class="{ 'is-active': mYieldSettings.ApplyGroupSize }">
                    <a @click="mYieldSettings.ApplyGroupSize = true">
                      <span>Yes</span>
                    </a>
                  </li>
                  <li :class="{ 'is-active': !mYieldSettings.ApplyGroupSize }">
                    <a @click="mYieldSettings.ApplyGroupSize = false">
                      <span>No</span>
                    </a>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </ui-component-modal>
</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
import spaceProvider from '@/providers/space'
import yieldProvider from '@/providers/yield'

import format from 'date-fns/format'
import VCalendar from 'v-calendar'
Vue.use(VCalendar, {})

export default {
  components: {},

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },

    yieldSetting: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      dataFormat: 'D MMMM YYYY',
      dateOne: new Date(),
      dateTwo: new Date(),
      isLoadingSpaces: false,
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      locationId: Number(this.$route.params.locationId),
      mYieldSettings: null,

      meetingtypes: [],
      priceCalculationTypes: [],
      spaces: [],
    }
  },

  computed: {
    ...mapState('locationStore', ['locationState']),
    ...mapState('spaceStore', ['space']),
    ...mapState('voucherStore', ['voucher']),

    channelName() {
      if (this.locationState) {
        let channelIndex = this.locationState.Channels.findIndex(
          (c) => c.ChannelId === this.mYieldSettings.ChannelId
        )
        if (channelIndex > -1) {
          return this.locationState.Channels[channelIndex].ChannelName
        }
      }
      return ''
    },
  },

  created() {
    this.mYieldSettings = JSON.parse(JSON.stringify(this.yieldSetting))
    this.mYieldSettings.Id = 0
    this.mYieldSettings.Status = 'Draft'

    this.dateOne = this.mYieldSettings.ValidFrom

    if (
      this.mYieldSettings.VoucherId === 0 &&
      this.mYieldSettings.SpaceId === 0
    ) {
      this.getLocationSpaces()
    }

    this.getMeetingtypes()

    this.priceCalculationTypes.push({
      id: 0,
      type: 'PerHourPerSeat',
      name: 'Price per seat per hour',
    })
    this.priceCalculationTypes.push({
      id: 1,
      type: 'PerDayPartOnly',
      name: 'Price per day part',
    })
    this.priceCalculationTypes.push({
      id: 2,
      type: 'PerDayPartPerPerson',
      name: 'Price per day part per person',
    })

    if (this.voucherId > 0) {
      this.yieldSettings.ValidFrom = this.voucher.ValidFrom
    }
  },

  methods: {
    formatDates(dateOne = '', dateTwo = '') {
      let formattedDates = ''
      if (dateOne) {
        formattedDates = format(dateOne, this.dataFormat)
      }
      return formattedDates
    },

    getLocationSpaces() {
      let self = this

      if (!self.isLoadingSpaces) {
        self.isLoadingSpaces = true

        spaceProvider.methods
          .getLocationSpaces(self.locationId, '', 0, 0, 0, 100)
          .then((response) => {
            if (response.status === 200) {
              self.spaces = response.data.Results
            }
          })
          .finally(() => {
            self.isLoadingSpaces = false
          })
      }
    },

    getMeetingtypes() {
      if (this.voucher) {
        this.meetingtypes = this.voucher.MeetingtypeIds
      } else {
        this.meetingtypes = this.locationState.Meetingtypes.map(
          (m) => m.MeetingtypeId
        )
      }
    },

    copyYieldSettings() {
      let self = this

      if (!self.isSaving) {
        self.isSaving = true

        self.mYieldSettings.ValidFrom = self.$options.filters.dateObjectIsoDateString(
          self.dateOne
        )

        yieldProvider.methods
          .createLocationYieldSettings(self.mYieldSettings)
          .then((response) => {
            if (response.status === 201) {
              self.isSavingSuccess = true

              setTimeout(() => {
                self.onClickCancel()
                self.$emit('yieldSettingCreated', response.data)
              }, 1500)
            }
          })
          .catch((error) => {
            self.isSavingError = true
          })
          .finally(() => {
            self.isSaving = false
          })
      }
    },
  },
}
</script>
